// Homepage incontinence callout.
.incontinence {
  @include grid-row;

  .widget.block.block-static-block {
    @include grid-column;
    text-align: center;

    > div {
      padding-top: $global-padding * 2;
      padding-bottom: $global-padding * 2;
      background-color: $white;
      box-shadow: 0 15px 40px rgba(50, 52, 71, 0.15);
    }

    .action {
      margin-top: $global-margin;

      .button {
        @include button($outline: true);
      }
    }
  }
}
