.page-header {
  
  > .panel.wrapper {

    > .header-banner {

      > .myaccount {
        @include grid-column(6);
        list-style: none;
        margin-bottom: 0;
        position: relative;
        margin-top: $global-margin / 4;
        margin-left: 0;
        border-right: 1px solid rgba($white, 0.5);

        @include breakpoint(medium) {
          @include grid-column(2);
        }

        li {
          top: $global-margin * -3;
          text-align: center;

          @include breakpoint(medium) {
            text-align: right;
          }

          a {
            padding: $global-padding / 3;
            display: inline-block;
            color: $white;
            position: relative;

            &::after {
              content: '';
              height: $global-padding / 6;
              width: 100%;
              background-color: transparent;
              position: absolute;
              bottom: 0;
              left: 0;
              transition: height 300ms ease, background-color 300ms ease;
            }

            &:hover {

              &::after {
                background-color: $orange;
                height: $global-padding / 6;
              }
            }
          }

          > ul {
            @include linear-gradient($primary-color, $secondary-color, bottom);
            list-style: none;
            border-radius: 0 0 10px 10px;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            display: none;
            box-shadow: 0 10px 15px rgba($black, 0.1);

            li {
              text-align: center;
            }
          }

          &:hover {

            > ul {
              display: block;
            }
          }
        }
      }
    }
  }
}
  