// Product grid for search results and product category pages
.catalogsearch-result-index,
.catalog-category-view {

  .product-grid,
  .products.grid {

    .product-item {
      padding: $global-padding !important;
      padding-top: 0;
      box-sizing: border-box;
      background-color: $white;
      text-align: center;
      position: relative;

      @include breakpoint(medium) {
        min-height: $global-padding * 31;
      }

      @include breakpoint(small only) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        min-height: $global-padding * 25;
      }

      .product.details {

        .product-attribute {
          color: $primary-color;
        }

        .price-final_price {

          span {
            font-weight: bold;
            color: $grey-dark;
          }
        }

        .product.actions {
          margin: $global-margin 0;

          @include breakpoint(medium) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }

          > div {
            margin: 0 auto;
          }

          a {

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

// Related Products Grid
.grid.products-grid.products-related {

  .product-items {

    li {
      min-height: $global-padding * 12;
  
      @include breakpoint(medium) {
        @include grid-column(4);
        float: left !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
