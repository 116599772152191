.why-reassure {
  @include grid-row;

  .reassure-difference {
    @include grid-column;

    @include breakpoint(medium) {
      @include grid-column(8);
    }

    .block-static-block {
      padding: $global-padding * 4;
      padding-top: $global-padding * 5;

      > div:first-child {
        float: none;

        @include breakpoint(medium) {
          float: left;
          width: 60%;
        }
      }

      > div:last-child {
        float: none;

        @include breakpoint(medium) {
          float: left;
          width: 40%;
        }
      }
    }
  }  

  .reassure-guarantee {
    @include grid-column;
    text-align: center;

    @include breakpoint(medium) {
      @include grid-column(4);
    }

    img {
      margin-bottom: $global-margin * 2;
      width: 100%;
    }
  }

  .reassure-difference,
  .reassure-guarantee {
    margin-bottom: $global-margin * 2;

    .block-static-block {
      background-color: $white;
      box-shadow: 0 15px 40px rgba(50, 52, 71, 0.15);
      min-height: $global-padding * 25;

      .button {
        margin-bottom: $global-margin;
      }
    }
  }
}
