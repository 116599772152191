
// Category banner Owl slider styles
.custom-slider {
  margin-top: $global-margin * -2;
  padding-bottom: 0;

  @include breakpoint(small only) {
    display: none;
  }

  .owl-carousel {

    .owl-stage-outer {
      min-height: auto;
      padding-top: 0;
    }

    .banner-item {
      margin: 0 !important;

      .content_slider {
        @include grid-row;
        // max-width: $grid-row-narrow;
        width: 100%;
        top: 15%;
        left: 0;
        right: 0;

        @include breakpoint(xlarge) {
          padding-left: $global-padding * 2;
        }

        .banner-title {
          @include grid-column(12);
          text-align: left;
          font-weight: bold;
          text-transform: none;
          color: $black;
        }

        .buttons {
          @include grid-column(12);
          // padding-left: $global-padding / 2;
          width: 100%;
          text-align: left;

          a {
            @include button();
          }
        }
      }
    }
  }
}
