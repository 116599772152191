
.breadcrumbs {
  @include grid-row;
  // max-width: $grid-row-narrow;
  padding: 0;
  float: none;

  // this is a gross fix that appeared to be needed when moving to staging
  // this div was inheriting styles from page-main
  > div {
    background: transparent !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
  }

  ul {
    margin-bottom: $global-margin / 2;
    padding-left: 0 !important; // another gross fix that the child div is inheriting  the page-main class

    li {
      font-weight: normal;
      display: inline;
      padding-left: $global-padding / 2;
      padding-right: $global-padding / 2;

      @include breakpoint(medium) {
        padding-left: $global-padding * 0.75;
        padding-right: $global-padding * 0.75;
      }

      a {
        color: $grey;
        font-weight: normal;

        &:hover {
          color: $primary-color;
        }
      }

      strong {
        font-weight: normal;
        color: darken($grey, 25%);
      }

      &:not(:first-child)::before {
        content: ' > ';
        padding-right: $global-padding * 0.75;
      }
    }
  }
}
