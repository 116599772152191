.minicart-wrapper {
  // @include grid-column(2);
  padding: 0 !important;
  top: $global-padding * 0.4;
  right: $global-padding * 2;
  color: $white !important;
  margin-left: 0;

  &.active {
    
    .block-minicart {
      border: 0;
      box-shadow: 0 0 13px rgba($black, 0.15);
      border-radius: $global-radius / 3;
      right: 0;
      color: $grey-dark;
      padding: ($global-padding * 2) ($global-padding);
      top: $global-padding * 2.5;
      width: $global-padding * 22;

      @include breakpoint(small only) {
        margin-right: $global-margin * -1.25;
      }

      .block-content {
        padding: 0 $global-padding;
      }
    }
  }

  .action.showcart {
    line-height: 1rem;
    display: inline-block;
    position: relative;
    background-color: transparent;

    &::before {
      font-size: 0;
      display: none;
    }

    &::after {
      content: '';
      color: $white;
      background: url("../images/sprite-1x.png") no-repeat -81px -97px;
      width: 40px;
      height: 25px;
      background-repeat: no-repeat;
      display: inline-block;
      margin-bottom: $global-margin / -3;
    }

    .counter.qty {
      display: inline-block;

      .counter-label {
        display: block !important;
        position: inherit !important;
        overflow: visible;
        width: 100%;
      }
    }
  }

  // .showcart {

  //   .counter.qty {

  //     .counter-label {
  //       display: block !important;
  //       // position: unset !important;
  //       // overflow: visible;
  //       // height: $global-padding * 2;
  //       // width: 100%;
  //       // clip: unset;
  //       // display: inline-block;
  //     }
  //   }
  // }

  .text {
    display: inline-block;
    text-indent: -9999px;
    font-size: 0;
    color: transparent;
    width: 0;
  }

  // .block-title {
  //   display: none;
  // }

  .counter-label {
    text-align: center;
    color: $white;
    font-size: $global-font-size * 1.2;
    padding: $global-padding / 4;
    line-height: 1;
    min-width: $global-padding * 1.1;
    height: $global-padding * 1.1;
    transition: color 300ms, background-color 300ms;
  }

  .counter-number {
    display: none !important;
  }

  .block-minicart {
    text-align: center;
    
    .action.close {
      z-index: 1;
      padding: 0 ($global-padding * 1.75);
      height: $global-padding * 2;
      right: 0;
      top: $global-padding / 2;
      transition: color 500ms ease, background-color 500ms ease;

      @include icon($position: before, $icon-set: fontawesome, $icon: close) {
        color: $primary-color;
        font-size: $global-font-size * 1.33;
        border: 1px solid $primary-color;
        border-radius: 50%;
        padding: ($global-padding * 0.1) ($global-padding * 0.7);
        transition: color 500ms ease, background-color 500ms ease;
      };

      &:hover {
        color: $white;

        @include icon($position: before, $icon-set: fontawesome, $icon: close) {
          color: $white;
          background-color: $primary-color;
        };
      }
    }

    .product-item-details {
      padding-left: $global-padding;
      padding-top: 0;
      padding-bottom: $global-padding;
    }

    .subtotal {
      width: 100%;
      margin-bottom: 0;
      margin-top: $global-margin;
      border-bottom: 1px solid $grey-light;

      > * {
        float: left;
        width: 50%;
        font-weight: bold;
        text-align: left;
        margin-bottom: $global-margin / 2;
      }

      .amount.price-container {
        text-align: right;
      }
    }

    .actions:not(.product) {

      > .primary {
        display: block;
        padding-top: $global-padding;

        > * {
          margin-top: $global-margin / 2;
          width: 100%;
        }
      }

      > .secondary {

        a {
          color: $grey;
          transition: color 500ms ease;
          font-size: $global-font-size * 0.875;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    .minicart-items-wrapper {

      ol {
        border-top: none;
        margin-top: 0;
        padding-top: 0;

        li {

          .minicart-price {
            font-weight: 500;
          }
        }
      }


      .product.actions {
        position: inherit;
        text-align: left;

        > div {
          font-size: $global-font-size;
          margin-left: 0;
          margin-bottom: $global-margin;
        }

        .action.delete span,
        .action.edit span {
          display: inline-block;
          font-weight: normal;
          color: $grey;
          font-size: $global-font-size * 0.875;
          font-weight: 400;
          transition: color 500ms ease;
          position: relative !important;
          clip: unset;
          overflow: visible;

          &:hover {
            color: $primary-color;
          }
        }

        .action.edit {
          padding-right: $global-padding * 3;
          border-right: 1px solid $grey;
          line-height: 1;
        }

        .action.delete {
          padding-left: $global-padding;
        }

        .action.delete::before,
        .action.edit::before {
          display: none;
        }
      }
    }
  }
}
