// CMS Our Difference Page
.cms-our-difference {

  .main {
    @include grid-row;

    > ol,
    > p {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(6);
      }
    }

    > ol {
      margin-left: 0;
    }
  }
}
