.subscribeAndSave-wrapper {
  background-color: $blue-light;
  position: relative;
  margin-bottom: $global-margin * 2;

  .subscribeAndSave {
    @include grid-row;
    padding-top: $global-padding * 2;
    padding-bottom: $global-padding * 2;
    min-height: $global-padding * 35;

    > div {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(6);
      }
    }

    .subscribeAndSave-Inner {
      box-shadow: 0 30px 60px rgba(32, 64, 88, 0.15);
      border-radius: $global-radius / 2;
      background-color: $white;
      margin-top: $global-margin * 3;
      padding-top: $global-padding * 2;
      padding-right: $global-padding;
      padding-bottom: $global-padding;
      padding-left: $global-padding * 4;
      max-width: 80%;

      @include breakpoint (small only) {
        position: absolute;
        left: $global-padding * 3;
        top: 0; // $global-padding * 17
        width: 100%;
      }

      h2 {
        margin-bottom: $global-margin * 2;
      }

      div {
        position: relative;
        margin-bottom: $global-margin * 3;

        span {
          background: $primary-color;
          border-radius: 50%;
          // padding: ($global-padding / 2) ($global-padding * 1.3);
          color: $white;
          font-size: $global-font-size * 1.75;
          font-weight: bold;
          border: 7px solid $white;
          position: absolute;
          left: $global-padding * -6;
          top: $global-padding * -1;
          text-align: center;
          height: $global-padding * 4.5;
          width: $global-padding * 4.5;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      p {

        a {
          @include button($outline: true);
        }
      }
    }
  }
}
