// This is a file that contains overrides - primarily from AAI Wampa for very specific mobile styles and tweaks.

// When we open the offcanvas nav - add a background color.
.nav-sections {
  @include breakpoint(medium down) {
    transition: left 0.3s;
    height: 100%;
    left: -80%;
    left: calc(-1 * (100% - 54px));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    width: calc(100% - 54px);
  }

  .nav-open & {
    background-color: $white;

    @include breakpoint(medium down) {
      box-shadow: 0 0 5px 0 rgba($black, 0.75);
      left: 0;
      z-index: 99;
      max-width: 95vw;
    }
  }

  &.sections {
    @include breakpoint(medium down) {
      max-width: 95vw;
    }
  }
}

// This is ugly, overrides some of the VES megamenu module styling for mobile.
// TODO - refactor it.
.nav-mobile {
  .dropdown-menu {
    .content-wrap {
      .megamenu-content {
        @include breakpoint(small only) {
          padding: 0 ($global-padding * 2);
        }
      }
    }
  }
}

// Main navigation styling
.nav-sections-item-content {
  background-color: transparent;

  .navigation {
    ul {
      position: static;
      padding-top: $global-padding;

      @include breakpoint(medium down) {
        padding-left: $global-padding / 4;
        width: 100%;
      }
    }

    .level0 {
      @include breakpoint(medium down) {
        display: block;
        width: 100%;
      }

      > .submenu {
        background: $white;
        border: 1px solid $grey-light;
        box-shadow: 0 5px 5px rgba($black, 0.19);
        font-weight: 400;
        min-width: rem-calc(230);
        padding: $global-padding 0;
        display: none;
        left: 0;
        margin: 0 !important;
        padding: 0;
        position: absolute;
        z-index: 1;
        margin-top: $global-margin * 0.75;

        @include breakpoint(medium down) {
          .megamenu-sidebar,
          .megamenu-content {
            width: 100% !important;
          }
        }

        // mega menu dropdown with images
        .submenu.dropdown-mega {
          background: transparent;
          position: static;
          border: 0;
          display: block;

          a {
            padding: 0;

            &:hover {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}
