// Homepage product category callouts.
.category-callouts {
  @include grid-column(12);
  margin-top: $global-margin * 4;
  margin-bottom: $global-margin * 2;

  @include breakpoint(medium) {
    margin-top: $global-margin * 3;
  }

  > div {
    @include grid-column(12);

    @include breakpoint(medium) {
      @include grid-column(4);
    }

    > div.inner {
      display: block;
      border-radius: $global-radius / 2;
      border: 1px solid $grey-light;
      padding: $global-padding * 3;
      min-height: $global-padding * 30;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: $global-margin;

      > img {
        margin-bottom: $global-margin * 2;
      }

      .button {

        @include breakpoint(medium down) {
          white-space: normal; // allow button text to wrap if needed
        }
      }
    }

    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {

      div.inner {
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint(medium down) {
          background-size: cover;
        }
      }
    }

    &:first-child {

      div.inner {
        background-image: url('../images/category1-bg.svg');
      }
    }

    &:nth-child(2) {

      div.inner {
        background-image: url('../images/category2-bg.svg');
      }
    }

    &:nth-child(3) {

      div.inner {
        background-image: url('../images/category3-bg.svg');
      }
    }
  }
}
