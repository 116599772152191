.ves-menu {
  // @include grid-column(12);
  // background-color: $grey-light;

  // @include breakpoint(medium only) {
  //   position: relative;
  // }

  // @include breakpoint(medium) {
  //   padding-bottom: 0;
  //   @include grid-column(12);
  // }

  // @include breakpoint(large) {
  //   @include grid-column(8);
  //   padding-left: 0 !important;
  //   padding-top: 0;
  //   background-color: transparent;
  // }
  width: 100%;

  @include breakpoint(large) {
    text-align: right;
  }

  ul {
    background-color: transparent;
    position: static;
    margin: 0;

    @include breakpoint(large) {
      text-align: center;
      float: none;
    }

    a {
      display: block;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .caret {
    display: none;
  }

  // .megamenu-content,
  // .megamenu-sidebar,
  // .megamenu-footer {

  //   @include breakpoint(small only) {
  //     float: none !important;
  //     clear: both;
  //   }
  // }

  .megamenu-content {
    @include breakpoint(small only) {
      margin-top: $global-margin;
    }
  }

  li.level0 {

    // @include breakpoint(medium) {
    //   position: static !important;
    //   display: inline-block;
    //   float: none;
    // }

    @include breakpoint(large) {
      // border: 0;
      position: static !important;
    }

    .opener {
      padding: $global-padding * 1.5;

      &::before {
        position: absolute;
        top: 40%;
        right: $global-margin * 1.1;
      }
    }

    > a {
      text-transform: capitalize;
      line-height: $header-height / 2;
      position: relative;
      font-weight: bold;
      float: none;
      font-size: $global-padding * 1.1;

      @include breakpoint(large) {
        line-height: $global-padding * 6;
        float: left;
      }
    }

    > .submenu.dropdown-menu {

      @include breakpoint(medium down) {
        padding: 0;
        border: 0;
      }

      @include breakpoint(large) {
        background-image: url('../images/megamenu-bg.svg');
        background-position: top right;
        background-repeat: no-repeat;
        background-size: initial;
        background-color: $white !important;
        border: 0 !important;
        top: 0 !important;
        text-align: center;
      }

      @include breakpoint(large) {
        background-size: $global-padding * 70;
      }

      @include breakpoint(xlarge) {
        background-size: contain;
      }

      // @include breakpoint(medium only) {
      //   top: $global-padding * 9 !important;
      //   background-color: $white !important;
      //   right: 0;
      //   background-size: contain;
      // }

      @include breakpoint(large) {
        top: $global-padding * 4 !important;
        right: $global-padding * 4;
      }

      > .content-wrap {
        @include grid-row;
      }
    }

    // @include breakpoint(small only) {

    //   &.open {

    //     > .submenu {
    //       display: block;
    //     }
    //   }

    //   .megamenu-sidebar.left-sidebar,
    //   .megamenu-content {
    //     width: 100% !important;
    //   }
    // }
  }

  .level1 {

    @include breakpoint(small only) {
      padding: 0 !important;
    }

    > a {
      text-transform: capitalize !important;
      line-height: $global-padding * 1.2 !important;
      padding-left: $global-padding * 1.875;
      padding-right: $global-padding * 1.875;

      @include breakpoint(small only) {
        padding: $global-padding * 0.9 !important;
        
        &:hover {
          background-color: $grey-light;
        }
      }

      @include breakpoint(medium) {
        padding: 0 $global-padding !important;
      }
    }

    .mega-col {

      @include breakpoint(small only) {
        padding: 0 !important;
      }
    }

    .submenu {
      box-shadow: none !important;
      padding: 0 $global-padding !important;
    }

    .megamenu-content {

      p {

        img {
          display: none;

          @include breakpoint(medium) {
            display: block;
            margin-top: $global-margin;
            max-width: 150%;
            margin-left: rem-calc(-10);
          }

          &:hover {
            transform: none; // default VES megamenu hover on images > transform: scale(1.04);
          }
        }
      }

      li {
        display: block;
        float: none;

        a {
          padding: ($global-padding / 4) 0;
          font-size: $global-font-size;
          color: $primary-color;
          font-weight: bold;

          &:hover {
            color: $secondary-color;
          }
        }
      }
    }

    .opener {
      display: none;
    }

    .mega-col-level-1 {
      width: auto;
      float: none;

      @include breakpoint(medium) {
        width: 25%;
        float: left;
        border-left: 1px solid $grey-light;
        padding: ($global-padding * 3) ($global-padding * 3) ($global-padding * 2);
        min-height: $global-padding * 20;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .megamenu-sidebar.left-sidebar {
    text-align: left;
    background-color: $white;
    padding: $global-padding * 2;

    // @include breakpoint(medium) {
    //   margin-left: rem-calc(-20);
    // }

    @include breakpoint(large) {
      padding-right: $global-padding * 4;
    }

    &::after {
      content: '';
      clear: both;
      display: block;
    }

    h3,
    h3 a {
      font-weight: bold !important;
      margin-bottom: $global-margin;
    }

    li {
      display: block;
      float: left;
      width: 48%;
      margin-right: 2%;
      text-align: left;

      a {
        padding: ($global-padding / 4) 0 !important;
        font-size: $global-padding * 1.1;
      }

      @include breakpoint(small only) {
        a {
          padding: ($global-padding / 2) 0 !important;
        }
      }
    }

    .button {
      background-color: $orange;
      border: 1px $orange;
      padding: ($global-padding / 2) ($global-padding) !important;
      color: $white !important;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .menu-hover .subhover:hover .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
}

header {

  .ves-menu {
    padding-top: $global-padding;
    padding-bottom: $global-padding;

    @include breakpoint(large) {
      padding-top: 0;
      padding-bottom: 0;
      height: $global-padding * 6;
    }

    .level1 {

      > a {
        color: $primary-color !important;
        font-weight: 500 !important;
        font-size: $global-font-size * 1.1;
        padding-left: $global-padding * 1.875;
        padding-right: $global-padding * 1.875;

        @include breakpoint(small only) {
          border-bottom: 1px solid $grey-light;
        }

        @include breakpoint(medium) {
          font-size: $global-font-size * 1.375;
        }

        &:hover {
          background: transparent !important;
        }
      }
    }

    li.level0 {

      @include breakpoint(medium only) {
        position: static !important;
        border-right: 3px solid $grey-light;
      }

      > a {

        span:first-of-type::after {
          content: '';
          height: $global-padding / 6;
          width: 100%;
          background-color: transparent;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: height 300ms, background-color 300ms;
        }
      }

      &:hover {

        > a {
          background-color: transparent !important;
          position: relative;

          span:first-child::after {

            @include breakpoint(large) {
              background-color: $orange;
              height: $global-padding / 6;
            }
          }
        }
      }
    }
  }
}
