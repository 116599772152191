//Site Footer
footer {
  position: relative;
  margin-top: $global-margin * 15;

  > .footer.content {
    @include grid-row;
    padding: 0;

    ul {
      list-style: none;
      text-align: center;
      margin-bottom: $global-margin * 3;
      padding-top: $global-padding;
      border-top: 1px solid rgba($white, 0.25);

      li {
        display: block;
        margin-right: $global-margin * 2;
        margin-bottom: $global-margin / 2;

        @include breakpoint(medium) {
          display: inline-block;
        }
      }
    }

    a {
      color: $white;
      font-weight: 600;
      position: relative;

      &::after {
        content: '';
        height: $global-padding / 6;
        width: 100%;
        background-color: transparent;
        position: absolute;
        bottom: $global-padding / -2;
        left: 0;
        transition: height 300ms ease, background-color 300ms ease;
      }

      &:hover {

        &::after {
          background-color: $orange;
          height: $global-padding / 6;
        }
      }
    }
  }

  > .footer-certifications {
    @include grid-row;
    text-align: center;

    img {
      margin: 0 ($global-margin * 2);
    }
  }

  &.page-footer {
    background-color: $blue;

    // Curved background before footer.
    &:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 20%;
      background-image: url('../images/footer-bg.svg');
      // background-color: $white;
      background-size: cover;
      position: absolute;
      top: $global-padding * -4;
      left: 0;
      right: 0;
      z-index: -1;

      @include breakpoint(medium) {
        height: 50%;
        top: $global-padding * -10;
      }

      @include breakpoint(xlarge) {
        height: 75%;
        top: $global-padding * -12;
      }
    }

    // On product view pages add in white before footer curve element
    .catalog-product-view & {
      margin-top: $global-margin * 4;

      @include breakpoint(medium) {
        margin-top: $global-margin * 10;
      }

      &::before {
        background-color: $white;
      }
    }
  }

  // modal footers should inherit the background color of the modal itself, usually white.
  &.modal-footer {
    background-color: transparent;
  }
}
