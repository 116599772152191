.sales-order-view {

  .order-actions-toolbar {

    .actions {

      a {
        @include linear-gradient($primary-color, darken($primary-color, 2%), bottom);
        border-radius: $button-radius;
        transition: background-color 500ms ease;
      }

      a:hover {
        @include linear-gradient($primary-color, darken($primary-color, 10%), bottom);
      }
    }
  }
}
