.header-links-container {
  @include icon(before, menu, fontawesome) {
    color: $grey;
    font-size: $global-font-size * 1.5;
    float: right;
    margin-top: $global-margin * -3.5;
    margin-right: $global-margin * 1.5;

    @include breakpoint(medium) {
      margin-top: $global-margin * -2.2;
      margin-right: $global-margin * 10.5;
    }

    @include breakpoint(large) {
      margin-right: $global-margin * 1.5;
    }
  };
  cursor: pointer;

  &:hover {
    @include icon(before, close, fontawesome) {
      color: $grey;
      font-size: $global-font-size * 1.5;
      float: right;
      margin-top: $global-margin * -3.5;
      margin-right: $global-margin * 1.5;

      @include breakpoint(medium) {
        margin-top: $global-margin * -2.2;
        margin-right: $global-margin * 10.5;
      }

      @include breakpoint(large) {
        margin-right: $global-margin * 1.5;
      }
    };

    > .header.links {
      visibility: visible;
      opacity: 1;
    }
  }

  .header.links {
    visibility: hidden;
    opacity: 0;
    background-color: $white;
    color: $grey-dark;
    font-size: $global-font-size * 0.875;
    box-shadow: 0 0 13px scale-color($black, $alpha: -85%);
    width: rem-calc(270);
    position: absolute;
    top: $global-padding * 9;
    right: $global-padding * 3;
    z-index: 10;
    overflow: hidden;
    padding: ($global-padding * 0.3125) ($global-padding * 1.625) ($global-padding * 0.5625);
    transition: visibility 300ms ease, opacity 300ms ease;

    @include breakpoint(medium) {
      top: $global-padding * 4;
      right: $global-padding * 7;
    }

    li {
      display: block;
      margin-top: $global-margin;
      font-size: $global-font-size;
    }

    li a {
      color: $grey;
      font-weight: 500;
      display: block;
      transition: color 300ms ease;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}
