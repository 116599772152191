// Form styling overrides.
// Base styles inheriteed from HDIS Default.

select {

  &:disabled {
    background-color: rgba($secondary-color, 0.15);
  }
}

[type=text],
[type=text]:focus,
[type=password],
[type=password]:focus,
[type=date],
[type=date]:focus,
[type=datetime],
[type=datetime]:focus,
[type=datetime-local],
[type=datetime-local]:focus,
[type=month],
[type=month]:focus,
[type=week],
[type=week]:focus,
[type=email],
[type=email]:focus,
[type=number],
[type=number]:focus,
[type=search],
[type=search]:focus,
[type=tel],
[type=tel]:focus,
[type=time],
[type=time]:focus,
[type=url],
[type=url]:focus,
[type=color],
[type=color]:focus,
input,
input:focus,
textarea,
textarea:focus,
select,
select:focus {
  border-radius: 4px;
}
