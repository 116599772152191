.catalog-category-view {

  .category-cms {
    margin-top: $global-margin * -2;

    img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
}
