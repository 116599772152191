
.sidebar {

  // Account navigation sidebar
  .account-nav {

    ul {

      li {

        &.current {
          background-color: rgba($blue-light, 0.5);
        }
      }
    }
  }
}
