// Product view page
.catalog-product-view {

  .page-main {
    background-color: $white;
    padding-top: $global-padding * 2;
    padding-bottom: $global-padding * 2;
    margin-top: $global-margin;

    .product-info-main {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(6);
        // @include grid-column-position(6);
      }

      .page-title-wrapper.product {
        padding: 0;
      }

      // This is a gross fix for the import of products randomly inserting em tags.
      > em {
        font-style: normal;
      }
    }

    .product.info.detailed {
      @include grid-column(12);
    }

    .attribute.overview {
      font-size: $global-padding * 1.2;
      font-weight: bold;
      margin-bottom: $global-margin;

      // This is a gross fix for the import of products randomly inserting em tags.
      > em {
        font-style: normal;
      }
    }

    .product-info-price {
      display: flex;
      flex-direction: column;
      padding-bottom: $global-padding;
      border-bottom: 1px solid $grey-light;

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
      }

      .price-box {

        .price {
          color: $body-font-color;
          font-size: $global-padding * 2 !important;
        }
      }

      .product-info-stock-sku {

        @include breakpoint(medium) {
          text-align: right;
          margin-top: $global-margin * 0.75;
        }

        .stock {

          &.available {
            color: map-get($foundation-palette, success);
          }

          &:not(.available) {
            color: map-get($foundation-palette, alert);
          }

          &::before {
            content: 'Availability:';
            color: $grey;
          }
        }

        .sku {

          > * {
            display: inline;
          }

          .type {

            &::after {
              content: ':';
            }
          }
        }
      }
    }

    .product-reviews-summary {
      margin-bottom: $global-margin / 2;

      > div {
        display: inline;

        a + a {
          float: none;
          display: block;
          border-left: none;
          margin-top: $global-margin / -2;
          margin-left: 0;
          padding-left: 0;

          @include breakpoint(medium) {
            float: right;
            margin-top: 0;
          }
        }
      }

      .rating-summary {
        margin-right: $global-margin / 2;
      }
    }

    .product-add-form {
      padding-top: $global-padding;

      .product-options-wrapper {
        margin-bottom: $global-margin;
        padding-right: $global-padding;
        display: block;
      }

      .fieldset {
        @include grid-row($behavior: nest);
        border: 0;
        padding: 0;
        margin: 0;

        .field.configurable,
        .field.configurable + .field.configurable,
        .field.required {
          @include grid-column(12);

          @include breakpoint(medium) {
            @include grid-column(6);
          }
        }

        div.mage-error {
          margin-top: $global-margin * -1;
        }

        label {
          color: $primary-color;
          float: left;
          margin-right: $global-margin;
          padding-top: $global-padding / 2;
        }

        input {
          float: left;
          width: 20%;
          text-align: center;
        }
      }

      .product-options-bottom {
        display: block;

        .fieldset {
          border: 0;
          padding: 0;
          margin: 0;

          input {
            width: 40%;
          }

          > div {
            float: left;
            width: 50%;
          }
        }

        .field.qty {
          max-width: 100%;
          margin-top: $global-margin / 3;
          width: 50%;
        }
      }
    }

    .product.media {
      @include grid-column(12);
      position: relative;

      @include breakpoint(medium) {
        @include grid-column(6);
      }
    }

    .product-details {

      .product.attribute.description {
        margin-bottom: $global-margin * 0.75;

        > .type {
          font-size: $global-font-size * 1.5;
          color: $primary-color;
          font-weight: bold;
          display: block;
        }
      }

      iframe {
        display: block;
        margin-top: $global-margin * 2;
        margin-bottom: $global-margin * 2;
      }

      .block-title  {
        color: $primary-color;
        font-size: $global-font-size * 1.5;
        margin-bottom: $global-margin * 1.5;
      }

      h2 {
        @include header-size(h3);
        margin-bottom: $global-margin * 3;
      }

      > div {

        // This is a gross fix for the import of products randomly inserting em tags.
        > em {
          font-style: normal;
        }
      }
    }
  }

  &:not(.page-product-configurable) {

    .page-main {

      .product-add-form {

        .fieldset {
          @include grid-row();
          border: 0;
          padding: 0;
          margin: 0;

          > div {
            @include grid-column(6);
            padding-left: 0 !important;
            float: left !important;

            &.field.qty {
              margin-top: $global-margin / 2;
            }

            &:only-of-type {
              float: left !important;
            }
          }

          input {
            float: left;
            width: 50%;
            text-align: center;
          }
        }
      }
    }
  }

  .breadcrumbs {

    .page-main {
      background-color: transparent;
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

body {
  .product-add-form .box-tocart .value-button {
    height: 1.5rem;
    line-height: 1.5;
    border-radius: 0 4px 0 0;
    &.decrease {
      border-radius: 0 0 4px 0;
    }
  }
}

@media only screen and (max-width: 800px) {
  body {
    .product-add-form .box-tocart .value-button {
      height: 3rem;
      line-height: 3rem;
      border-radius:4px;
      padding: 0;
      &.decrease {
        border-radius:4px;
      }
    }
  }
}
