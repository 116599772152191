.image-slider-owl,
.owl-carousel {
  // margin-top: $global-margin * 0.2;
  margin-top: 0;
  margin-bottom: 0;

  // &.hasOwl {
  //   max-width: 100%;
  //   overflow-x: hidden;
  //   margin-top: $global-margin * 0.2;
  // }

  .owl-nav {
    margin-top: 0;
  }

  .owl-stage-outer {
    // min-height: rem-calc(530);
    padding: 0;
  }

  .owl-prev,
  .owl-next {
    font-size: 0;
    width: rem-calc(45);
    height: rem-calc(45);
    transition: color 500ms ease, background-color 500ms ease;
    background-color: $primary-color;
    color: $white;
    border-radius: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -20%;

    @include breakpoint(medium) {
      // margin-top: -22%;
      width: rem-calc(70);
      height: rem-calc(70);
    }

    &:hover {
      background-color: $blue-light;
      color: $primary-color;
    }
  }

  .owl-prev {
    @include icon(before, arrow-left, fontawesome) {
      color: $white;
      font-size: $global-font-size * 1.75;
    };
    float: left;

    &:hover {
      @include icon(before, arrow-left, fontawesome) {
        color: $primary-color;
        font-size: $global-font-size * 1.75;
      };
    }
  }

  .owl-next {
    @include icon(before, arrow-right, fontawesome) {
      color: $white;
      font-size: $global-font-size * 1.75;
    };
    float: right;

    &:hover {
      @include icon(before, arrow-right, fontawesome) {
        color: $primary-color;
        font-size: $global-font-size * 1.75;
      };
    }
  }

  .owl-dots {
    position: relative;
    margin-top: $global-margin * -2.5;

    @include breakpoint(medium) {
      margin-top: $global-margin * -4;
    }

    .owl-dot {

      span {
        background-color: rgba($white, 0.5);
        height: $global-padding;
        width: $global-padding;

        @include breakpoint(medium) {
          height: $global-padding * 1.5;
          width: $global-padding * 1.5;
        }
      }

      &.active {

        span {
          background-color: $white;
        }
      }
    }
  }

  .item-content {
    position: relative;

    div:first-of-type {
      @include grid-row;

      > * {
        @include grid-column(6);
        float: left !important;
        clear: both;
        

        @include breakpoint(medium) {
          @include grid-column(4);
        }
      }
    }

    div:last-of-type {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    a {
      @include button;
    }
  }
}
