
// Upsell products block on product pages
.block.row {

  #block-upsell-heading, // sass-lint:disable-line no-ids
  .products-upsell {
    @include grid-column(12);
    padding-left: map-get($grid-column-gutter, small);
    padding-right: map-get($grid-column-gutter, small);

    @include breakpoint(medium) {
      padding-left: map-get($grid-column-gutter, medium);
      padding-right: map-get($grid-column-gutter, medium);
    }
  }

  #block-upsell-heading { // sass-lint:disable-line no-ids
    font-weight: 700;
    font-size: $global-font-size * 1.5;
  }
}
