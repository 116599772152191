[type="submit"],
[type="submit"]:disabled,
[type="button"],
button:not(.js-hide),
button:not(.action-close),
.button:not(.js-hide),
.button.primary:not(.js-hide),
.button {
  @include button;

  &.button-reverse {
    @include button($outline: true);
  }

  &.button-alt {
    @include button($alt: true);
  }
}

.button.secondary:not(.js-hide) {
  @include button($outline: true);
}

.alt[type="submit"]:not(.js-hide),
.alt[type="submit"]:disabled:not(.js-hide),
.alt[type="button"]:not(.js-hide),
.button.alt:not(.js-hide) {
  @include button($alt: true);
}

[type="submit"]:disabled {
  background-color: transparent;
  color: $primary-color;

  &:after {
    color: $primary-color;
  }

  &:hover {
    background-color: transparent;
    color: $grey-light;

    &:after {
      color: $grey-light;
    }
  }
}

// Custom modal overlay overrides from HDIS Default
body {

  &._has-modal,
  &._has-modal-custom {

    .modal-popup._show,
    .modal-custom._show {

      .modal-header {

        .action-close {

          &:after {
            font-size: $global-font-size * 1.5;
            color: $grey;
          }

          &:hover {

            &:after {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
