.page-header {
  background-color: $white;
  box-shadow: 0 1px 1px scale-color($black, $alpha: -92%);
  margin-bottom: $global-margin * 2 !important;
  position: relative;

  > .panel.wrapper {
    @include grid-column;
    background-color: $primary-color !important;
    padding-top: $global-padding;
    position: relative;

    @include breakpoint(small only) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    > .header-banner {
      @include grid-row;
      padding-bottom: $global-padding / 2;
    
      > .panel.header {
        display: none;
      }

      // > .minicart {
      //   @include grid-column(6);

      //   @include breakpoint(medium) {
      //     @include grid-column(1);
      //   }
      // }
    }
  }

  > .header.content {
    @include grid-row;
    clear: both;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    // Nav toggle changes from HDIS Default
    // Mobile Nav Toggle
    .action.nav-toggle {
      position: absolute;
      z-index: 99;
      top: $global-padding * 2.2;

      @include breakpoint(medium) {
        display: block;
      }

      @include breakpoint(large) {
        display: none;
      }
    }

    > .logo {
      @include grid-column(11);
      text-align: left;

      @include breakpoint(medium) {
        @include grid-column(3);
      }

      img {
        width: $global-padding * 12;
        max-width: none;
        vertical-align: top;
        padding-top: $global-padding / 2;
        margin: ($global-margin / 2) 0 0 !important;

        @include breakpoint(large) {
          margin-top: $global-margin * 0.75;
          margin-bottom: 0;
          max-width: rem-calc(185);
          width: auto;
        }
      }
    }

    > .nav-sections {

      @include breakpoint(large) {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }

  .panel.header {
    display: none;
  }
}

// logo adjustments for Reassure
.page-wrapper {

  .page-header {

    .header.content {

      .logo {
        margin-bottom: $global-margin;

        @include breakpoint(medium) {
          padding-left: $global-padding * 2;
        }
      }
    }
  }
}

// Page specific page header styling
.cms-home {
  
  .page-header {
    margin-bottom: 0 !important;
  }
}

.checkout-index-index {

  header {

    > .header.content {

      > .logo {
        float: left !important;
      }
    }
  }
}
