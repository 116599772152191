.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: $global-padding * 4;
  text-align: center;
  background-color: rgba($white, 0.75);
  z-index: 100;

  .loader {
    background-color: rgba($white, 0.75);
    height: 100vh;

    p {
      font-weight: bold;
      font-size: $global-font-size * 1.5;
      margin-top: $global-margin * -2;
    }
  }
}

// Page animation for mobile nav
.page-wrapper {
  transition: left 400ms ease;

  .nav-before-open & {
    position: relative;
    overflow: hidden;
    height: 100%;
    transition: left 400ms ease;
  }

  // .nav-open & {
  //   left: 80%;
  // }
}

// 2 Column layout changes - primarily for search and filters
.page-layout-2columns-left {

  &.catalogsearch-result-index,
  &.page-with-filter {

    .page-main {

      > .main {

        @include breakpoint(medium) {
          @include grid-column(8);
          @include grid-column-position(4);
        }
      }

      > .sidebar {

        @include breakpoint(medium) {
          @include grid-column(4);
          @include grid-column-position(-8);
          margin-top: $global-margin * 2;
        }
      }
    }
  }

  // Product page width overrides
  &.page-products {

    .page-main {
      // max-width: $grid-row-width;

      // For tablet and smaller we want the layered navigation to come before the products.
      @include breakpoint(small only) {
        display: flex;
        flex-direction: column;

        .main {
          order: 2;

          // First toolbar is hidden for tablet and down.
          .toolbar.toolbar-products {
            display: none;
          }

          .products.wrapper {
            // We had to hide both, so need to bring the second one back.
            + .toolbar.toolbar-products {
              display: block;
            }
          }
        }

        .sidebar {
          order: 1;
        }
      }
    }
  }
}
