//Footer newsletter block
footer {
  
  > .footer.content {

    > .block.newsletter {
      @include grid-row;
      float: none;
      text-align: center;

      .form.subscribe {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .label {
        visibility: hidden;
        font-size: 0;
        width: 0;
        height: 0;
      }

      .title {
        color: $white;
        font-size: $global-font-size * 1.125;
        margin-bottom: $global-margin;
      }

      input {
        border-radius: 31px 0 0 31px;
        height: $global-padding * 4.1;
        padding-left: $global-padding * 2;
        border: 1px solid $white;
      }

      button {
        @include button ($reverse: true);
        border-radius: 0 31px 31px 0;
        padding-left: $global-padding * 1.25;
        padding-right: $global-padding * 1.25;
        padding-top: $global-padding * 1.22;
        padding-bottom: $global-padding * 1.22;
        border: none !important;
      }

      .mage-error {
        background-color: $white;
        padding-left: $global-padding;
        padding-right: $global-padding;
      }
    }
  }
}
