
.page-header {

  > .panel.wrapper {
    
    > .header-banner {


      > .block.block-search {
        // This is set to 11 columns to account for the nav-toggle placement.
        @include grid-column(11);
        position: relative;
        float: right;
        
        @include breakpoint(medium) {
          @include grid-column(3);
        }
      
        input,
        input:focus {
          border-radius: $global-radius;
          padding-left: $global-padding * 2;
          font-size: $global-font-size * 0.75;
          border: none;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
          transition: box-shadow 400ms ease;
        }
      
        input:focus {
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
        }

        .field.search {

          // Adds our search icon and positions it "inside" the input.
          > .control {
            @include icon($position: before, $icon-set: fontawesome, $icon: search) {
              color: $primary-color;
              position: absolute;
              top: $global-padding * 0.15;
              // left: 0;
              margin-left: $global-margin / 2;
              color: $primary-color;
              font-size: $global-font-size * 1.4;
            };
            position: relative;
          }
        }
      }
    }
  }
}
